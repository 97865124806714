import countries from 'constants/countries';

/**
 * Formats a price as a localized currency string.
 * @param price - The numeric value of the price. Default is 0.
 * @param currency - The ISO 4217 currency code (e.g., 'USD', 'EUR'). Default is 'ISK'.
 * @param countryCode - The ISO 3166-1 alpha-2 country code (e.g., 'US', 'DE'). Default is 'IS'.
 * @returns The formatted currency string.
 * @throws Will throw an error if the price is invalid.
 */

const formatCurrencyPrice = (price = 0, currency = 'ISK', countryCode = 'IS'): string => {
  // Normalize explicitly passed `undefined` or `null`
  price = price ?? 0;
  currency = currency ?? 'ISK';
  countryCode = countryCode ?? 'IS';

  const languageCode = countries?.find(c => c.value === countryCode)?.languageCode || 'de-DE';

  try {
    const formatter = new Intl.NumberFormat(languageCode, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 10,
      currencyDisplay: 'narrowSymbol',
    });

    return formatter.format(price);
  } catch (error) {
    console.error('Error formatting currency:', error);
    throw new Error(
      `Failed to format the price for currency: ${currency}, country: ${countryCode}. Please check inputs.`,
    );
  }
};

export default formatCurrencyPrice;
