import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Row } from 'antd';

import { ILoginData, ILoginFormProps } from './login_form.types';
import styles from './login_form.module.scss';

const loginTranslationsPrefix = 'login_page';

const STEPS = {
  EMAIL: 'email',
  OTPCONFIRMATION: 'otpConfirmation',
};

const LoginForm: React.FC<ILoginFormProps> = ({ onLogin, onPasswordReset }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [isForgotMode, setIsForgotMode] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [didResetPassword, setDidResetPassword] = useState(false);
  const [currentStep, setCurrentStep] = useState<string>(STEPS.EMAIL);

  const forgotButtonText = isForgotMode
    ? t(`${loginTranslationsPrefix}.have_password`)
    : t(`${loginTranslationsPrefix}.forgot_password`);

  function submitButtonText(): string {
    if (!isForgotMode) return t(`${loginTranslationsPrefix}.sign_in`);
    if (!didResetPassword) return t(`${loginTranslationsPrefix}.reset_password`);
    return t(`${loginTranslationsPrefix}.email_sent`);
  }

  const handleSubmit = async (formValues: ILoginData): Promise<void> => {
    setSubmitting(true);
    try {
      if (!isForgotMode) {
        const dataLogin = await onLogin(formValues);
        if (dataLogin?.otp?.otpClientChallenge) {
          form.setFieldValue('otpClientChallenge', dataLogin?.otp?.otpClientChallenge);
          setCurrentStep('otpConfirmation');
        }
      } else {
        await onPasswordReset(formValues.email);
      }
      if (isForgotMode) {
        message.success(t(`${loginTranslationsPrefix}.password_reset_email_sent`));
        setDidResetPassword(true);
      }
    } catch (error) {
      message.error(t('general.no_access_error'));
    } finally {
      setSubmitting(false);
    }
  };

  const toggleForgotMode = (): void => {
    form.resetFields(['password', 'otp', 'otpClientChallenge']);
    setCurrentStep('email');
    setIsForgotMode(forgot => !forgot);
  };

  return (
    <Form
      name="login"
      className={styles.root}
      onFinish={handleSubmit}
      form={form}
      initialValues={{ email: '', password: '' }}
    >
      <Form.Item
        name="email"
        rules={[{ required: true, message: t(`${loginTranslationsPrefix}.please_input_email`) }]}
        hidden={currentStep === STEPS.OTPCONFIRMATION}
      >
        <Input
          autoFocus
          aria-label="email"
          type="email"
          size="large"
          prefix={<UserOutlined className={styles.input_icon} />}
          placeholder={t('general.other.email')}
        />
      </Form.Item>
      {!isForgotMode && (
        <Form.Item
          name="password"
          hidden={currentStep === STEPS.OTPCONFIRMATION}
          rules={[{ required: true, message: t(`${loginTranslationsPrefix}.please_input_password`) }]}
        >
          <Input.Password
            aria-label="password"
            size="large"
            prefix={<LockOutlined className={styles.input_icon} />}
            placeholder={t('general.other.password')}
          />
        </Form.Item>
      )}
      {currentStep === STEPS.OTPCONFIRMATION && (
        <>
          <div className={styles.confirmation_code_label}>
            <div>{t(`${loginTranslationsPrefix}.sent_email_confirmation_code`)}</div>
            <div>{t(`${loginTranslationsPrefix}.please_enter_it_below`)}</div>
          </div>
          <Form.Item
            name="otp"
            rules={[{ required: true, message: t(`${loginTranslationsPrefix}.please_input_confirmation_code`) }]}
          >
            <Input aria-label="otp" size="large" placeholder={t(`${loginTranslationsPrefix}.confirmation_code`)} />
          </Form.Item>
          <Form.Item
            name="otpClientChallenge"
            hidden={true}
            rules={[{ required: true, message: t(`${loginTranslationsPrefix}.please_input_email`) }]}
          >
            <Input autoFocus aria-label="otpClientChallenge" size="large" />
          </Form.Item>
        </>
      )}

      <Row justify="center">
        <Button
          className={styles.login_button}
          type="primary"
          htmlType="submit"
          size="large"
          shape="round"
          loading={isSubmitting}
          disabled={isForgotMode && didResetPassword}
        >
          {submitButtonText()}
        </Button>
      </Row>
      <Row justify="center">
        <Button type="link" disabled={isSubmitting} onClick={toggleForgotMode}>
          {forgotButtonText}
        </Button>
      </Row>
    </Form>
  );
};

export default LoginForm;
