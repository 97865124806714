// Generated with util/create-component.js
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UploadFile } from 'antd/lib/upload';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Upload, Form, Input, InputNumber, Select, Popconfirm, Tooltip, Radio, Collapse, Checkbox, Button } from 'antd';

import PDF_FONTS from 'constants/pdf_fonts';
import { IGiftCardPdfFormProps } from './gift_card_pdf_form.types';
import styles from './gift_card_pdf_form.module.scss';
import { IPhoto } from 'components/gift_cards_store_settings_form/gift_cards_store_settings_form.types';
import { IFilledSection } from 'components/gift_card_pdf_details/gift_card_pdf_details.types';

const { TextArea } = Input;

const alignOptions = [
  {
    value: 'left',
    label: 'Left',
  },
  {
    value: 'center',
    label: 'Center',
  },
  {
    value: 'right',
    label: 'Right',
  },
];

const GiftCardPdfForm: React.FC<IGiftCardPdfFormProps> = ({
  filledSection,
  index,
  isDefaultTemplate,
  onFieldChange,
}) => {
  const { t } = useTranslation();

  const [backgroundType, setBackgroundType] = useState<number>(0);

  const requiredRules = [{ required: false, message: t('validation_messages.required') }];

  const pdfSection = filledSection.pdfSection;
  const fontKinds = [
    'text',
    'gift_card_title',
    'gift_card_recipient',
    'gift_card_generated_id',
    'gift_card_info',
    'gift_card_description',
    'gift_card_expiration',
    'booking_url',
    'hotel_name',
  ];
  const fontSizeKinds = [
    'text',
    'gift_card_title',
    'gift_card_recipient',
    'gift_card_generated_id',
    'gift_card_info',
    'gift_card_description',
    'gift_card_expiration',
    'booking_url',
    'hotel_name',
    'draw_rectangle',
  ];
  const textKinds = [
    'text',
    'gift_card_generated_id',
    'gift_card_info',
    'gift_card_expiration',
    'booking_url',
    'gift_card_recipient',
  ];
  const paddingKinds = [
    'text',
    'gift_card_title',
    'gift_card_recipient',
    'gift_card_generated_id',
    'gift_card_generated_id',
    'gift_card_info',
    'gift_card_description',
    'gift_card_expiration',
    'booking_url',
    'hotel_name',
    'image',
  ];
  const imageKinds = ['background', 'image'];
  const colorFillKinds = ['color_fill', 'background', 'qr_code', 'draw_rectangle'];
  const recipientSectionNames = ['Gift Card Header', 'Gift Card Title', 'Gift Card Description'];

  const showFontSelection = useMemo(() => {
    return fontKinds.includes(pdfSection.kind);
  }, [pdfSection]);

  const showFontSizeSelection = useMemo(() => {
    return fontSizeKinds.includes(pdfSection.kind);
  }, [pdfSection]);

  const showPaddingSelection = useMemo(() => {
    return paddingKinds.includes(pdfSection.kind);
  }, [pdfSection]);

  const showImageSelection = useMemo(() => {
    return imageKinds.includes(pdfSection.kind);
  }, [pdfSection]);

  const showColorFillSelection = useMemo(() => {
    return colorFillKinds.includes(pdfSection.kind);
  }, [pdfSection]);

  const showTextSelection = useMemo(() => {
    return textKinds.includes(pdfSection.kind);
  }, [pdfSection]);

  const showAddRecipientTemplateValue = useMemo(() => {
    if (pdfSection.name === null) {
      return false;
    }
    return recipientSectionNames.includes(pdfSection.name);
  }, [pdfSection.kind]);

  const showDashSelection = pdfSection.kind === 'dashed_box';

  const showOpacitySelection = pdfSection.kind === 'image';

  const showCornerRadiusSelection = pdfSection.kind === 'draw_rectangle';

  const showStretchSelection = pdfSection.kind === 'image';

  const textLabel = useMemo(() => {
    if (
      ['booking_url', 'gift_card_expiration', 'gift_card_generated_id', 'gift_card_recipient'].includes(pdfSection.kind)
    ) {
      return t('gift_card.pdf_prepended_text');
    }

    return t('gift_card.pdf_text');
  }, [pdfSection.kind]);

  const fillColorTooltip = useMemo(() => {
    if (pdfSection.kind === 'draw_rectangle') {
      return t('gift_card.pdf_fill_color_draw_rectangle_tooltip');
    } else if (pdfSection.kind === 'background') {
      return t('gift_card.pdf_fill_color_background_tooltip');
    } else if (pdfSection.kind === 'qr_code') {
      return t('gift_card.pdf_fill_color_qr_code_tooltip');
    } else {
      return t('gift_card.pdf_fill_color_tooltip');
    }
  }, [pdfSection.kind]);

  const textTooltip = useMemo(() => {
    if (pdfSection.kind === 'booking_url') {
      return t('gift_card.pdf_text_booking_url_tooltip');
    } else if (pdfSection.kind === 'gift_card_expiration') {
      return t('gift_card.pdf_text_gift_card_expiration_tooltip');
    } else if (pdfSection.kind === 'gift_card_generated_id') {
      return t('gift_card.pdf_text_gift_card_generated_id_tooltip');
    } else if (pdfSection.kind === 'gift_card_recipient') {
      return t('gift_card.pdf_text_gift_card_recipient_tooltip');
    } else {
      return t('gift_card.pdf_text_tooltip');
    }
  }, [pdfSection.kind]);

  const fontSizeLabel = useMemo(() => {
    if (['draw_rectangle'].includes(pdfSection.kind)) {
      return t('gift_card.pdf_line_thickness');
    }

    return t('gift_card.pdf_font_size');
  }, [pdfSection.kind]);

  const fontSizeTooltip = useMemo(() => {
    if (['draw_rectangle'].includes(pdfSection.kind)) {
      return t('gift_card.pdf_line_thickness_tooltip');
    }

    return t('gift_card.pdf_font_size_tooltip');
  }, [pdfSection.kind]);

  useEffect(() => {
    const backgroundType = filledSection.photo === undefined ? 1 : 0;
    setBackgroundType(backgroundType);
  }, [filledSection]);

  const fonts = useMemo(() => {
    return PDF_FONTS.map((font: string) => ({ value: font, label: <div style={{ fontFamily: font }}>{font}</div> }));
  }, [PDF_FONTS]);

  const renderPhoto = (photo: IPhoto) => {
    return (
      <div
        data-testid="GiftCardPhoto"
        className={styles.giftCardPhotoContainer}
        onDragOver={event => event.preventDefault()}
      >
        <Tooltip title={t('photo.remove_photo_title')}>
          <Popconfirm
            title={
              <>
                <p>{t('general.confirm')}</p>
                <p>{t('photo.remove_photo')}</p>
              </>
            }
            cancelText={t('link.cancel')}
            okText={t('general.yes')}
            placement="bottomRight"
            onConfirm={() => {
              onFieldChange([index, 'photo'], null);
              onFieldChange([index, 'photoUrl'], null);
            }}
          >
            <div className={styles.deletePhotoButton}>
              <DeleteOutlined />
            </div>
          </Popconfirm>
        </Tooltip>
        <img src={photo.url} className={styles.giftCardPhoto} />
      </div>
    );
  };
  const getFile = (e: { fileList: UploadFile<IPhoto>[] }) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList[0];
  };

  const stretchOptions = [
    { value: false, label: t('general.no') },
    { value: true, label: t('general.yes') },
  ];

  const backgroundTypeOptions = [
    { value: 0, label: t('gift_card.pdf_use_photo') },
    { value: 1, label: t('gift_card.pdf_use_color') },
  ];

  const shouldUpdateImageBackground = (prevValues: IFilledSection[], currentValues: IFilledSection[]): boolean => {
    return prevValues[index]?.photo !== currentValues[index]?.photo;
  };

  const shouldUpdateImage = (prevValues: IFilledSection[], currentValues: IFilledSection[]): boolean => {
    return prevValues[index]?.photo !== currentValues[index]?.photo;
  };

  const shouldUpdateText = (prevValues: IFilledSection[], currentValues: IFilledSection[]): boolean => {
    return prevValues[index]?.text !== currentValues[index]?.text;
  };

  const shouldUpdateColorFillBackground = (prevValues: IFilledSection[], currentValues: IFilledSection[]): boolean => {
    return prevValues[index]?.fillColor !== currentValues[index]?.fillColor;
  };

  return (
    <div data-testid="GiftCardPdfForm" className={styles.root}>
      <legend> {filledSection?.pdfSection?.name} </legend>

      {pdfSection.kind === 'background' && (
        <Form.Item
          rules={requiredRules}
          label={t('gift_card.pdf_background_type')}
          tooltip={t('gift_card.pdf_background_type_tooltip')}
        >
          <Radio.Group
            options={backgroundTypeOptions}
            optionType="button"
            buttonStyle="outline"
            value={backgroundType}
            onChange={e => {
              setBackgroundType(e.target.value);
              onFieldChange([index, 'photo'], null);
            }}
          />
        </Form.Item>
      )}

      {showTextSelection && (
        <>
          <Form.Item noStyle shouldUpdate={shouldUpdateText}>
            <Form.Item
              name={[index, 'text']}
              rules={[{ required: true, message: t('validation_messages.required') }]}
              label={textLabel}
              tooltip={textTooltip}
            >
              <TextArea rows={4} />
            </Form.Item>
          </Form.Item>

          {showAddRecipientTemplateValue && (
            <Form.Item noStyle shouldUpdate={shouldUpdateText}>
              {({ getFieldValue }) => (
                <Form.Item
                  name={[index, 'addRecipient']}
                  label={t('gift_card.pdf_add_recipient')}
                  tooltip={t('gift_card.pdf_add_recipient_tooltip')}
                >
                  <Button
                    onClick={() => onFieldChange([index, 'text'], getFieldValue([index, 'text']) + '[recipient_name]')}
                  >
                    {' '}
                    {t('gift_card.pdf_recipient_name')}{' '}
                  </Button>
                </Form.Item>
              )}
            </Form.Item>
          )}
        </>
      )}

      {pdfSection.name === 'Gift Card Header' && isDefaultTemplate && (
        <Form.Item
          name={[index, 'visible']}
          label={t('gift_card.pdf_visible')}
          tooltip={t('gift_card.pdf_visible_tooltip')}
          valuePropName={'checked'}
        >
          <Checkbox />
        </Form.Item>
      )}

      {showImageSelection && pdfSection.kind === 'background' && (
        <Form.Item noStyle shouldUpdate={shouldUpdateImageBackground} hidden={backgroundType === 1}>
          {({ getFieldValue, setFieldValue }) => (
            <Form.Item
              data-testid="PdfPhoto"
              name={[index, 'photo']}
              label={t('gift_card.pdf_photo')}
              getValueFromEvent={getFile}
              rules={requiredRules}
            >
              <>
                <Upload
                  beforeUpload={() => false}
                  itemRender={(_originNode, file) => renderPhoto(file)}
                  accept={'.png,.jpeg,.jpg'}
                  multiple={false}
                  listType="picture-card"
                  maxCount={1}
                  fileList={getFieldValue([index, 'photo']) ? [getFieldValue([index, 'photo'])] : []}
                  onChange={({ file }) =>
                    setFieldValue([index, 'photo'], {
                      ...getFieldValue([index, 'photo']),
                      file,
                      url: URL.createObjectURL(file),
                    })
                  }
                >
                  {!getFieldValue([index, 'photo']) && (
                    <div className={styles.addNewPhoto}>
                      <PlusCircleOutlined className={styles.addPhoto} />
                      <div>{t('photo.add_new')}</div>
                    </div>
                  )}
                </Upload>
              </>
            </Form.Item>
          )}
        </Form.Item>
      )}

      {showImageSelection && pdfSection.kind !== 'background' && (
        <Form.Item noStyle shouldUpdate={shouldUpdateImage}>
          {({ getFieldValue, setFieldValue }) => (
            <Form.Item
              data-testid="PdfPhoto"
              name={[index, 'photo']}
              label={t('gift_card.pdf_photo')}
              getValueFromEvent={getFile}
              rules={requiredRules}
            >
              <>
                <Upload
                  beforeUpload={() => false}
                  itemRender={(_originNode, file) => renderPhoto(file)}
                  accept={'.png,.jpeg,.jpg'}
                  multiple={false}
                  listType="picture-card"
                  maxCount={1}
                  fileList={getFieldValue([index, 'photo']) ? [getFieldValue([index, 'photo'])] : []}
                  onChange={({ file }) =>
                    setFieldValue([index, 'photo'], {
                      ...getFieldValue([index, 'photo']),
                      file,
                      url: URL.createObjectURL(file),
                    })
                  }
                >
                  {!getFieldValue([index, 'photo']) && (
                    <div className={styles.addNewPhoto}>
                      <PlusCircleOutlined className={styles.addPhoto} />
                      <div>{t('photo.add_new')}</div>
                    </div>
                  )}
                </Upload>
              </>
            </Form.Item>
          )}
        </Form.Item>
      )}

      {showColorFillSelection && pdfSection.kind === 'background' && (
        <Form.Item noStyle shouldUpdate={shouldUpdateColorFillBackground}>
          <Form.Item
            label={t('gift_card.pdf_fill_color')}
            name={[index, 'fillColor']}
            rules={[{ required: false }]}
            className={styles.fillColorContainer}
            hidden={backgroundType === 0}
            tooltip={fillColorTooltip}
          >
            <Input placeholder={t('gift_card.pdf_fill_color')} type="color" className={styles.fillColor} />
          </Form.Item>
        </Form.Item>
      )}

      {showColorFillSelection && pdfSection.kind === 'qr_code' && (
        <Form.Item
          label={t('gift_card.pdf_color_fill')}
          name={[index, 'fillColor']}
          rules={[{ required: false }]}
          className={styles.fillColorContainer}
          tooltip={fillColorTooltip}
        >
          <Input placeholder={t('gift_card.pdf_fill_color')} type="color" className={styles.fillColor} />
        </Form.Item>
      )}

      {pdfSection.kind !== 'background' && pdfSection.kind !== 'qr_code' && (
        <>
          <Collapse>
            <Collapse.Panel header={'Design'} key={pdfSection.id}>
              {showStretchSelection && (
                <Form.Item
                  name={[index, 'stretch']}
                  rules={requiredRules}
                  label={t('gift_card.pdf_stretch')}
                  tooltip={t('gift_card.pdf_stretch_tooltip')}
                  data-testid="Stretch"
                >
                  <Radio.Group options={stretchOptions} optionType="button" buttonStyle="outline" />
                </Form.Item>
              )}

              {showFontSelection && (
                <Form.Item label={t('gift_card.pdf_font')} rules={requiredRules} name={[index, 'font']}>
                  <Select
                    showSearch
                    optionFilterProp="value"
                    filterOption={true}
                    placeholder={t('gift_card.pdf_font')}
                    options={fonts}
                  />
                </Form.Item>
              )}

              {showFontSizeSelection && (
                <Form.Item
                  name={[index, 'fontSize']}
                  rules={[...requiredRules]}
                  label={fontSizeLabel}
                  tooltip={fontSizeTooltip}
                >
                  <InputNumber placeholder={fontSizeLabel} min={1} max={100} />
                </Form.Item>
              )}

              {showFontSelection && (
                <Form.Item
                  name={[index, 'textAlign']}
                  rules={[{ required: false }]}
                  label={t('gift_card.pdf_text_align')}
                  tooltip={t('gift_card.pdf_text_align_tooltip')}
                >
                  <Select options={alignOptions} />
                </Form.Item>
              )}

              {showColorFillSelection && (
                <Form.Item
                  label={t('gift_card.pdf_fill_color')}
                  tooltip={fillColorTooltip}
                  name={[index, 'fillColor']}
                  rules={[{ required: false }]}
                  className={styles.fillColorContainer}
                >
                  <Input placeholder={t('gift_card.pdf_fill_color')} type="color" className={styles.fillColor} />
                </Form.Item>
              )}

              {showDashSelection && (
                <Form.Item
                  name={[index, 'dashSize']}
                  rules={[...requiredRules]}
                  label={t('gift_card.pdf_dash_size')}
                  tooltip={t('gift_card.pdf_dash_size_tooltip')}
                >
                  <InputNumber placeholder={t('gift_card.pdf_dash_size')} min={1} max={100} step={1} />
                </Form.Item>
              )}

              {showDashSelection && (
                <Form.Item
                  name={[index, 'dashSpace']}
                  rules={[...requiredRules]}
                  label={t('gift_card.pdf_dash_space')}
                  tooltip={t('gift_card.pdf_dash_space_tooltip')}
                >
                  <InputNumber placeholder={t('gift_card.pdf_dash_space')} min={1} max={100} step={1} />
                </Form.Item>
              )}

              {showOpacitySelection && (
                <Form.Item
                  name={[index, 'opacity']}
                  rules={[
                    { required: true, type: 'number', message: t('validation_messages.required'), min: 0, max: 1 },
                  ]}
                  label={t('gift_card.pdf_opacity')}
                  tooltip={t('gift_card.pdf_opacity_tooltip')}
                >
                  <InputNumber step={0.01} />
                </Form.Item>
              )}

              {showPaddingSelection && (
                <Form.Item
                  name={[index, 'paddingTop']}
                  rules={[
                    {
                      required: true,
                      type: 'number',
                      message: t('validation_messages.required'),
                      min: 0.0,
                      max: 841,
                    },
                  ]}
                  label={t('gift_card.pdf_padding_top')}
                  tooltip={t('gift_card.pdf_padding_top_tooltip')}
                >
                  <InputNumber step={1} />
                </Form.Item>
              )}

              {showPaddingSelection && (
                <Form.Item
                  name={[index, 'paddingBottom']}
                  rules={[
                    { required: true, type: 'number', message: t('validation_messages.required'), min: 0, max: 841 },
                  ]}
                  label={t('gift_card.pdf_padding_bottom')}
                  tooltip={t('gift_card.pdf_padding_bottom_tooltip')}
                >
                  <InputNumber step={1} />
                </Form.Item>
              )}

              {showPaddingSelection && (
                <Form.Item
                  name={[index, 'paddingLeft']}
                  rules={[
                    { required: true, type: 'number', message: t('validation_messages.required'), min: 0, max: 595 },
                  ]}
                  label={t('gift_card.pdf_padding_left')}
                  tooltip={t('gift_card.pdf_padding_left_tooltip')}
                >
                  <InputNumber step={1} />
                </Form.Item>
              )}

              {showPaddingSelection && (
                <Form.Item
                  name={[index, 'paddingRight']}
                  rules={[
                    { required: true, type: 'number', message: t('validation_messages.required'), min: 0, max: 595 },
                  ]}
                  label={t('gift_card.pdf_padding_right')}
                  tooltip={t('gift_card.pdf_padding_right_tooltip')}
                >
                  <InputNumber step={1} />
                </Form.Item>
              )}

              {showCornerRadiusSelection && (
                <Form.Item
                  name={[index, 'cornerRadius']}
                  rules={[
                    {
                      required: true,
                      type: 'number',
                      message: t('validation_messages.required'),
                      min: 0.0,
                      max: 360.0,
                    },
                  ]}
                  label={t('gift_card.pdf_corner_radius')}
                  tooltip={t('gift_card.pdf_corner_radius_tooltip')}
                >
                  <InputNumber step={1} />
                </Form.Item>
              )}
            </Collapse.Panel>
          </Collapse>
        </>
      )}
    </div>
  );
};

export default GiftCardPdfForm;
