import React, { useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import classNames from 'classnames';

import { SHARP_CORNERS } from 'constants/store_settings_corner_style';
import StoreNav from 'components/store_nav/store_nav';
import { PropertyGiftCardsDataContext } from 'containers/data_context';
import useTrackingCookieConsent from 'hooks/useTrackingCookieConsent';
import { TRACKING_STORAGE_KEY } from 'constants/common';

import styles from './gift_card_buy_store_page_layout.module.scss';
import { IGiftCardStorePageProps } from './gift_card_buy_store_page_layout.types';
import giftCardStoreThemeStyles from './gift_card_store_theme.module.scss';

const GiftCardStorePageLayout: React.FC<IGiftCardStorePageProps> = ({ children, className }) => {
  const { propertyInfo } = useContext(PropertyGiftCardsDataContext);
  const isTrackingAllowedIntially: boolean = useTrackingCookieConsent();
  const [isTrackingAllowed, setIsTrackingAllowed] = useState<boolean>(isTrackingAllowedIntially);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event?.type === 'storage') {
        const cached = localStorage.getItem(TRACKING_STORAGE_KEY);
        const newValue = cached ? JSON.parse(cached) : false;
        setIsTrackingAllowed(newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    const storeSetting = propertyInfo?.data?.storeSetting;
    if (storeSetting) {
      const { titleFont, textFont, tagColor, gaTrackingId } = storeSetting;
      document.documentElement.style.setProperty('--titleFont', titleFont);
      document.documentElement.style.setProperty('--textFont', textFont);
      document.documentElement.style.setProperty('--tagColor', tagColor);

      if (isTrackingAllowed && gaTrackingId && gaTrackingId !== '') {
        ReactGA.initialize([
          {
            trackingId: gaTrackingId,
          },
        ]);
        ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
      }
    }
  }, [propertyInfo?.data?.storeSetting, isTrackingAllowed]);

  return (
    <div
      className={classNames(
        giftCardStoreThemeStyles.root,
        propertyInfo?.data?.storeSetting?.cornerStyle === SHARP_CORNERS ? '' : giftCardStoreThemeStyles.roundedTheme,
        className,
      )}
    >
      <header>
        <StoreNav />
      </header>
      <section className={styles.giftCardStoreContent}>{children}</section>
    </div>
  );
};

export default GiftCardStorePageLayout;
