// Generated with util/create-component.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col, Popover } from 'antd';
import classNames from 'classnames';

import formatCurrencyPrice from 'utils/format_currency_price';
import calculatePercentage from 'utils/calculate_percentage';

import { IGiftCardStoreListItemProps } from './gift_card_store_list_item.types';
import styles from './gift_card_store_list_item.module.scss';

const GiftCardStoreListItem: React.FC<IGiftCardStoreListItemProps> = ({
  giftCard,
  offset,
  itemSize,
  property,
  handleRedirect,
}) => {
  const { t } = useTranslation();
  const renderGiftCardDescription = () => {
    const description = giftCard?.description?.replace(/(<([^>]+)>)/gi, '');
    if (description?.length > 120) {
      const content = <div className={styles.popoverContent}>{description}</div>;
      return (
        <div className={styles.popoverWrap} data-testid="GiftCardStoreListItem.Popover">
          <Popover title={giftCard.title} content={content} trigger={'click'} destroyTooltipOnHide>
            {`${description.substring(0, 120)}...`}
          </Popover>
        </div>
      );
    } else {
      return description;
    }
  };
  return (
    <Col
      data-testid="GiftCardStoreListItem"
      key={`giftCard-${giftCard.id}`}
      md={itemSize}
      offset={offset}
      className={styles.giftCard}
    >
      <Row className={classNames(styles.giftCardContainer, 'roundedWrapper')} justify="space-between">
        <Col span={24}>
          <Row>
            <Col span={24} className={styles.imageContainer}>
              <img
                data-testid="GiftCardStoreListItem.Image"
                src={
                  giftCard.photos?.[0]?.publicUrl ||
                  'https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/best-gift-cards-2021-1637270343.jpg?crop=1.00xw:0.652xh;0,0.160xh&resize=980:*'
                }
                onClick={() => handleRedirect(giftCard)}
                className="roundedWrapper"
                alt="Gift Card"
              />
            </Col>
            <Col span={24} className={classNames('highlighted', styles.giftCardPrice)}>
              <Row justify="space-between" align="middle" gutter={[10, 10]} className={styles.giftCardPriceDetails}>
                <Col
                  data-testid="GiftCardStoreListItem.Price"
                  span={
                    giftCard.netPrice !== giftCard.price && calculatePercentage(giftCard.netPrice, giftCard.price)
                      ? 12
                      : 24
                  }
                >
                  {formatCurrencyPrice(giftCard.netPrice, property?.currency, property?.country)}
                </Col>
                {giftCard.netPrice !== giftCard.price && calculatePercentage(giftCard.netPrice, giftCard.price) && (
                  <>
                    <Col span={12} data-testid="GiftCardStoreListItem.DiscountPercent">
                      <div className={'discountPercentage'}>
                        {calculatePercentage(giftCard.netPrice, giftCard.price)}
                      </div>
                    </Col>
                    <Col span={12} className={'discountPrice'} data-testid="GiftCardStoreListItem.DiscountPrice">
                      {formatCurrencyPrice(giftCard.price, property?.currency, property?.country)}
                    </Col>
                  </>
                )}
              </Row>
            </Col>
            <Col
              span={24}
              className={classNames('highlighted', styles.giftCardName)}
              onClick={() => handleRedirect(giftCard)}
              data-testid="GiftCardStoreListItem.Title"
            >
              {giftCard.title}
            </Col>
            <Col span={24} className={styles.giftCardDescription} data-testid="GiftCardStoreListItem.Description">
              {renderGiftCardDescription()}
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row className={styles.buttonContainer}>
            <Col span={24}>
              <Button
                className={classNames(styles.chooseButton, 'highlighted')}
                onClick={() => handleRedirect(giftCard)}
                data-testid="GiftCardStoreListItem.Button"
              >
                {t('gift_card.view_certificates')}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default GiftCardStoreListItem;
