// Generated with util/create-component.js
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { Row, Col, Button, DatePicker, Result } from 'antd';
import type { GetProps } from 'antd';
import { CreditCardOutlined, HomeOutlined } from '@ant-design/icons';

import GiftCardPhotosGallery from 'components/gift_card_photos_gallery/gift_card_photos_gallery';
import GiftCardDatePicker from 'components/gift_card_date_picker/gift_card_date_picker';

import { IRatePlan, IPropertyUnit } from 'components/gift_card_store_booking/gift_card_store_booking.types';

import GiftCardPropertyInfo from './gift_card_property_info/gift_card_property_info';
import GiftCardUnits from './gift_card_units/gift_card_units';

import { IGiftCardStoreBookingProps } from './gift_card_store_booking.types';
import styles from './gift_card_store_booking.module.scss';
type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

const GiftCardStoreBooking: React.FC<IGiftCardStoreBookingProps> = ({
  giftCard,
  propertyInfo,
  dates,
  onBook,
  onDatesChange,
  closedDates,
  units,
  isLoadingGiftCardUnitsData,
}) => {
  const [availability, setAvailability] = useState<boolean>(false);
  const [properyGiftCardType, setProperyGiftCardType] = useState<boolean>(true);
  const [selectedRatePlan, setRatePlanToBook] = useState<IRatePlan>();
  const [selectedRoomType, setRoomTypeToBook] = useState<IPropertyUnit>();
  const [isSelectedUpsell, isSetSelectedUpsell] = useState<boolean>(false);

  const handleBook = () => {
    if (selectedRoomType && selectedRatePlan) {
      onBook(selectedRoomType, selectedRatePlan, isSelectedUpsell);
    }
  };

  useEffect(() => {
    if (giftCard && !giftCard.channelId) {
      setProperyGiftCardType(false);
    }
  }, [giftCard]);

  useEffect(() => {
    if (units) {
      setAvailability(false);
      const hasAvailableRatePlans = units.find(unit => unit.ratePlans.length > 0);
      if (hasAvailableRatePlans) {
        setAvailability(true);
      }
    }
  }, [units]);

  const { t } = useTranslation();

  const handleDatesChange = useCallback((dates: RangePickerProps['value']) => {
    onDatesChange(dates);
  }, []);

  const noAvailability = (
    <div className={styles.noAvailabilityWrapper}>
      <div className={styles.noAvailability}>{t('no_available_rooms')}</div>
      <div className={styles.differentDate}>{t('choose_different_date')}</div>
    </div>
  );

  const handleSelectRatePlan = (unit: IPropertyUnit, ratePlan: IRatePlan, isUpsell: boolean) => {
    setRatePlanToBook(ratePlan);
    setRoomTypeToBook(unit);
    isSetSelectedUpsell(isUpsell);
  };

  const giftCardPhotos = useMemo(
    () =>
      propertyInfo?.content?.photos?.map(p => {
        return { publicUrl: p.url, id: Number(p.id) };
      }),
    [propertyInfo?.content?.photos],
  );

  const propertyDetails = {
    currency: propertyInfo?.currency,
    country: propertyInfo?.country,
  };

  const showNoAvailability = !availability && !isLoadingGiftCardUnitsData && dates?.[0] && dates?.[1];
  const showSubmitButtonRow = availability && !isLoadingGiftCardUnitsData;

  return (
    <div data-testid="GiftCardStoreBooking" className={styles.root}>
      {!properyGiftCardType && <Result status="warning" title={t('general.non_accomodation_gift_card_info')} />}
      <Row>
        <Col sm={11} xs={24}>
          <GiftCardPhotosGallery photos={giftCardPhotos} />
        </Col>
        <Col sm={13} xs={24} className={styles.textContainer}>
          <Row>
            <Col span={24} className={styles.propertyInfoContainer}>
              <GiftCardPropertyInfo propertyInfo={propertyInfo} />

              {properyGiftCardType && (
                <>
                  {(!dates?.[0] || !dates?.[1]) && (
                    <div className={classNames(styles.selectDatesLabel, 'highlighted')}>
                      {t('general.select_dates_to_book')}
                    </div>
                  )}
                  <div className={styles.giftCardDetailsContainer}>
                    <div className={styles.giftCardDetails}>
                      <CreditCardOutlined />
                      {giftCard?.title}
                    </div>
                    <div className={styles.giftCardDetails}>
                      <HomeOutlined />
                      {`${giftCard?.settings?.lengthOfStay || 1} ${t('hotel_page.nights')}`}
                    </div>
                  </div>

                  <GiftCardDatePicker
                    onDatesChange={handleDatesChange}
                    dates={dates}
                    maxDays={giftCard?.settings?.lengthOfStay || 1}
                    maxDate={dayjs(giftCard?.expirationDate)}
                    closedDatesPerRate={closedDates}
                  />
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {properyGiftCardType && (
        <>
          <Row>
            <Col span={24}>
              {availability && (
                <GiftCardUnits
                  units={units}
                  isLoadingGiftCardUnitsData={isLoadingGiftCardUnitsData}
                  onBook={handleSelectRatePlan}
                  numberOfNights={giftCard?.settings?.lengthOfStay || 1}
                  upsellRatePlans={giftCard?.upsellRatePlans || []}
                  property={propertyDetails}
                />
              )}
              {showNoAvailability && <div>{noAvailability}</div>}
            </Col>
          </Row>

          {showSubmitButtonRow && (
            <Row justify="end">
              <Button onClick={handleBook} type="primary" htmlType="submit" size="large" disabled={!selectedRatePlan}>
                {isSelectedUpsell ? t('gift_card.book_and_pay') : t('general.book')}
              </Button>
            </Row>
          )}
        </>
      )}
    </div>
  );
};

export default GiftCardStoreBooking;
