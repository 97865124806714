import { IPhoto } from 'components/gift_card_form/gift_card_form.types';

export const duplicatePhoto = async (photoUrl: string) => {
  if (!photoUrl) {
    return null;
  }

  try {
    const response = await fetch(photoUrl);
    const blob = await response.blob();

    const timestamp = Date.now();
    const randomString = Math.random().toString(36).substring(2, 15);
    const dynamicFileName = `${timestamp}_${randomString}.jpeg`;
    const file = new File([blob], dynamicFileName, {
      type: blob.type,
    });

    const url = URL.createObjectURL(file);

    return {
      file,
      url,
    };
  } catch (error) {
    console.error('Error creating duplicate image:', error);
    return null;
  }
};

export const duplicateGiftCardPhoto = async (photo: string) => {
  const duplicatedPhoto = await duplicatePhoto(photo);
  if (duplicatedPhoto) {
    return {
      isRemoved: false,
      file: duplicatedPhoto.file,
      publicUrl: duplicatedPhoto.url,
    };
  }
  return null;
};

const duplicateGiftCardPhotos = async (photos: IPhoto[]): Promise<IPhoto[]> => {
  const duplicatedPhotos = await Promise.all(
    photos?.map(photo => {
      if (!photo.publicUrl) return null;
      return duplicateGiftCardPhoto(photo.publicUrl);
    }),
  ).then(photos => photos?.filter(photo => photo));
  return duplicatedPhotos as IPhoto[];
};

export default {
  duplicatePhoto,
  duplicateGiftCardPhoto,
  duplicateGiftCardPhotos,
};
