import React, { useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Row } from 'antd';

import routes from 'routing/routes';
import buildPath from 'utils/build_path';
import useTrackingCookieConsent from 'hooks/useTrackingCookieConsent';

import GiftCardStoreListItem from 'components/gift_card_store_list_item/gift_card_store_list_item';

import { IGiftCardStoreListProps, IPublishedGiftCard } from './gift_card_store_list.types';

const GiftCardStoreList: React.FC<IGiftCardStoreListProps> = ({ giftCardsList, property }) => {
  const history = useHistory();

  const getItemSize = () => {
    if (window.innerWidth <= 750) return 24;
    if (750 <= window.innerWidth && window.innerWidth < 1100) return 12;
    if (1500 <= window.innerWidth && window.innerWidth <= 1700) return 6;
    if (1000 <= window.innerWidth && window.innerWidth <= 1950) return 8;
    if (1850 < window.innerWidth && window.innerWidth < 2000) return 6;

    return 6;
  };

  const { selectedProperty } = useParams<{ selectedProperty: string }>();
  const [itemSize, setItemSize] = useState<number>(getItemSize());
  const isTrackingAllowed: boolean = useTrackingCookieConsent();

  const handleRedirect = (giftCard: IPublishedGiftCard) => {
    isTrackingAllowed &&
      window?.gtag('event', 'view_item', {
        currency: property?.currency,
        value: giftCard.netPrice,
        items: [
          {
            item_id: giftCard.id,
            item_name: giftCard.title,
            item_category: 'Gift Card',
            price: giftCard.netPrice,
            discount: giftCard.price - giftCard.netPrice,
          },
        ],
      });
    const redirectPath = buildPath(history.location.search, routes.giftCardStoreDetailPage, {
      selectedProperty,
      giftCardId: giftCard?.id,
    });

    history.push(redirectPath);
  };

  window.addEventListener('resize', () => {
    const size = getItemSize();
    setItemSize(size);
  });

  const offsetSize = giftCardsList?.length < 24 / itemSize ? (24 - giftCardsList?.length * itemSize) / 2 : 0;

  const getOffset = (index: number) => {
    if (giftCardsList?.length < 24 / itemSize && (index === 0 || (index + 1) % (24 / itemSize) === 0))
      return offsetSize;
    return 0;
  };

  const renderGiftCardsContent = useMemo(() => {
    if (!giftCardsList?.length || !property) return;
    return giftCardsList?.map((giftCard: IPublishedGiftCard, index: number) => (
      <GiftCardStoreListItem
        key={`giftCard-${giftCard.id}`}
        giftCard={giftCard}
        offset={getOffset(index)}
        itemSize={itemSize}
        property={property}
        handleRedirect={handleRedirect}
      />
    ));
  }, [giftCardsList, itemSize, property]);

  return (
    <div data-testid="GiftCardStoreList">
      <Row gutter={40}>{renderGiftCardsContent}</Row>
    </div>
  );
};

export default GiftCardStoreList;
