import { useCallback, useMemo, useReducer } from 'react';

import { actions, INITIAL_STATE, reducer } from './reducers/property_gift_cards';
import {
  clearSavedState,
  getSavedState,
  setSavedState,
} from './reducers/property_gift_cards/utils/manage_saved_state';

export default () => {
  const [propertyGiftCardsTypeData, dispatch] = useReducer(reducer, INITIAL_STATE);

  const updateGiftCardType = useCallback(
    params => {
      return actions.updateGiftCardType(dispatch, params);
    },
    [dispatch],
  );

  const setGiftCardCheckoutData = useCallback(
    params => {
      return actions.setGiftCardCheckoutData(dispatch, params);
    },
    [dispatch],
  );

  const createGiftCardType = useCallback(
    params => {
      return actions.createGiftCardType(dispatch, params);
    },
    [dispatch],
  );

  const createGiftCard = useCallback(
    params => {
      return actions.createGiftCard(dispatch, params);
    },
    [dispatch],
  );

  const getGiftCardTypes = useCallback(
    params => {
      return actions.getGiftCardTypes(dispatch, params);
    },
    [dispatch],
  );

  const getGiftCardProperties = useCallback(
    params => {
      return actions.getGiftCardProperties(dispatch, params);
    },
    [dispatch],
  );

  const getStoreGiftCardTypes = useCallback(
    params => {
      return actions.getStoreGiftCardTypes(dispatch, params);
    },
    [dispatch],
  );

  const getGiftCardById = useCallback(
    params => {
      return actions.getGiftCardById(dispatch, params);
    },
    [dispatch],
  );

  const getAvailableUnits = useCallback(
    params => {
      return actions.getAvailableUnits(dispatch, params);
    },
    [dispatch],
  );

  const getGiftCardUnit = useCallback(
    params => {
      return actions.getGiftCardUnit(dispatch, params);
    },
    [dispatch],
  );

  const getGiftCardsByPropertyId = useCallback(
    params => {
      return actions.getGiftCardsByPropertyId(dispatch, params);
    },
    [dispatch],
  );

  const getPropertyInfo = useCallback(
    params => {
      return actions.getPropertyInfo(dispatch, params);
    },
    [dispatch],
  );

  const putGiftCardEmailSent = useCallback(
    params => {
      return actions.putGiftCardEmailSent(dispatch, params);
    },
    [dispatch],
  );

  const postGiftCardIssuedMultiple = useCallback(
    params => {
      return actions.postGiftCardIssuedMultiple(dispatch, params);
    },
    [dispatch],
  );

  const downloadGiftCardsPdfs = useCallback(
    params => {
      return actions.downloadGiftCardsPdfs(dispatch, params);
    },
    [dispatch],
  );

  const uploadGiftCardTypePhoto = useCallback(
    params => {
      return actions.uploadGiftCardTypePhoto(dispatch, params);
    },
    [dispatch],
  );

  const createGiftCardBooking = useCallback(
    params => {
      return actions.createGiftCardBooking(dispatch, params);
    },
    [dispatch],
  );

  const getGiftCardDetails = useCallback(
    params => {
      return actions.getGiftCardDetails(dispatch, params);
    },
    [dispatch],
  );

  const setGiftCardDetails = useCallback(
    params => {
      return actions.setGiftCardDetails(dispatch, params);
    },
    [dispatch],
  );

  const getGiftCardTypeSizes = useCallback(
    params => {
      return actions.getGiftCardTypeSizes(dispatch, params);
    },
    [dispatch],
  );

  const getGiftCardTypeSize = useCallback(
    params => {
      return actions.getGiftCardTypeSize(dispatch, params);
    },
    [dispatch],
  );

  const putGiftCardBookingEmailSent = useCallback(
    params => {
      return actions.putGiftCardBookingEmailSent(dispatch, params);
    },
    [dispatch],
  );

  const saveDataToStorage = useCallback(bookingDataToSave => {
    setSavedState(bookingDataToSave);
  }, []);

  const getDataFromStorage = useCallback(() => {
    return getSavedState();
  }, []);

  const clearDataFromStorage = useCallback(() => {
    clearSavedState();
  }, []);

  const setParams = useCallback(newParams => {
    return actions.setParams(dispatch, newParams);
  }, []);

  const getGiftCardsPurchaseStatus = useCallback(params => {
    return actions.getGiftCardsPurchaseStatus(dispatch, params);
  }, []);

  const getGiftCardsPurchases = useCallback(
    params => {
      return actions.getGiftCardsPurchases(dispatch, params);
    },
    [dispatch],
  );

  const putGiftCardPurchaseMarkPaid = useCallback(
    params => {
      return actions.putGiftCardPurchaseMarkPaid(dispatch, params);
    },
    [dispatch],
  );

  const putGiftCardPurchaseMarkIssued = useCallback(
    params => {
      return actions.putGiftCardPurchaseMarkIssued(dispatch, params);
    },
    [dispatch],
  );

  const putGiftCardPurchaseUpdateRecipients = useCallback(
    params => {
      return actions.putGiftCardPurchaseUpdateRecipients(dispatch, params);
    },
    [dispatch],
  );

  const getGiftCardBookingDetails = useCallback(params => {
    return actions.getGiftCardBookingDetails(dispatch, params);
  }, []);

  const getGiftCardClosedDates = useCallback(params => {
    return actions.getGiftCardClosedDates(dispatch, params);
  }, []);

  const loadGiftCardUnitsInfo = useCallback(params => {
    return actions.loadGiftCardUnitsInfo(dispatch, params);
  }, []);

  const getGiftCardDetailsPdf = useCallback(params => {
    return actions.getGiftCardDetailsPdf(dispatch, params);
  }, []);

  const getGiftCardDetailsPdfPreview = useCallback(params => {
    return actions.getGiftCardDetailsPdfPreview(dispatch, params);
  }, []);

  const getGiftCardDetailsQrCode = useCallback(params => {
    return actions.getGiftCardDetailsQrCode(dispatch, params);
  }, []);

  const cancelGiftCardBooking = useCallback(params => {
    return actions.cancelGiftCardBooking(dispatch, params);
  }, []);

  const loadPropertyUnitRatePlansList = useCallback(
    params => {
      return actions.loadPropertyUnitRatePlansList(dispatch, params);
    },
    [dispatch],
  );

  const createMultiGiftCardType = useCallback(
    params => {
      return actions.createMultiGiftCardType(dispatch, params);
    },
    [dispatch],
  );

  const updateMultiGiftCardType = useCallback(
    params => {
      return actions.updateMultiGiftCardType(dispatch, params);
    },
    [dispatch],
  );

  const deleteGiftCardTypePhoto = useCallback(
    params => {
      return actions.deleteGiftCardTypePhoto(dispatch, params);
    },
    [dispatch],
  );

  const deleteMultiGiftCardTypePhoto = useCallback(
    params => {
      return actions.deleteMultiGiftCardTypePhoto(dispatch, params);
    },
    [dispatch],
  );

  const resetGiftCardStoreBooking = useCallback(
    () => {
      return actions.resetGiftCardStoreBooking(dispatch);
    },
    [dispatch],
  );

  const putGiftCard = useCallback(
    params => {
      return actions.putGiftCard(dispatch, params);
    },
    [dispatch],
  );

  const putResetGiftCard = useCallback(
    params => {
      return actions.putResetGiftCard(dispatch, params);
    },
    [dispatch],
  );

  const getUserHotelRoomTypeRatePlans = useCallback(
    params => {
      return actions.getUserHotelRoomTypeRatePlans(dispatch, params);
    },
    [dispatch],
  ); 

  const getGiftCardsExport = useCallback(
    params => {
      return actions.getGiftCardsExport(dispatch, params);
    },
    [dispatch],
  );

  const getGiftCardPdfTemplates = useCallback(propertyId => {
    return actions.getGiftCardPdfTemplates(dispatch, propertyId);
  }, []);

  const getGiftCardPdfTemplateFilledSections = useCallback((propertyId, giftCardTypeId, pdfTemplateId) => {
    return actions.getGiftCardPdfTemplateFilledSections(dispatch, propertyId, giftCardTypeId, pdfTemplateId);
  }, []);

  const propertyGiftCardTypesActions = useMemo(
    () => ({
      getGiftCardById,
      getStoreGiftCardTypes,
      createGiftCardType,
      createGiftCard,
      getGiftCardTypes,
      getGiftCardProperties,
      updateGiftCardType,
      getAvailableUnits,
      getGiftCardUnit,
      getGiftCardsByPropertyId,
      saveDataToStorage,
      getDataFromStorage,
      clearDataFromStorage,
      setParams,
      getPropertyInfo,
      putGiftCardEmailSent,
      postGiftCardIssuedMultiple,
      downloadGiftCardsPdfs,
      uploadGiftCardTypePhoto,
      createGiftCardBooking,
      cancelGiftCardBooking,
      getGiftCardDetails,
      getGiftCardTypeSizes,
      getGiftCardTypeSize,
      putGiftCardBookingEmailSent,
      setGiftCardCheckoutData,
      getGiftCardsPurchaseStatus,
      getGiftCardsPurchases,
      putGiftCardPurchaseMarkPaid,
      putGiftCardPurchaseMarkIssued,
      putGiftCardPurchaseUpdateRecipients,
      getGiftCardBookingDetails,
      getGiftCardClosedDates,
      loadGiftCardUnitsInfo,
      getGiftCardDetailsPdf,
      getGiftCardDetailsQrCode,
      setGiftCardDetails,
      loadPropertyUnitRatePlansList,
      createMultiGiftCardType,
      updateMultiGiftCardType,
      deleteGiftCardTypePhoto,
      deleteMultiGiftCardTypePhoto,
      resetGiftCardStoreBooking,
      getGiftCardDetailsPdfPreview,
      putGiftCard,
      putResetGiftCard,
      getUserHotelRoomTypeRatePlans,
      getGiftCardsExport,
      getGiftCardPdfTemplates,
      getGiftCardPdfTemplateFilledSections,
    }),
    [
      getGiftCardById,
      getStoreGiftCardTypes,
      createGiftCardType,
      createGiftCard,
      getGiftCardTypes,
      getGiftCardProperties,
      updateGiftCardType,
      getAvailableUnits,
      getGiftCardUnit,
      getGiftCardsByPropertyId,
      saveDataToStorage,
      getDataFromStorage,
      clearDataFromStorage,
      setParams,
      getPropertyInfo,
      putGiftCardEmailSent,
      postGiftCardIssuedMultiple,
      downloadGiftCardsPdfs,
      uploadGiftCardTypePhoto,
      createGiftCardBooking,
      cancelGiftCardBooking,
      getGiftCardDetails,
      getGiftCardTypeSizes,
      getGiftCardTypeSize,
      putGiftCardBookingEmailSent,
      setGiftCardCheckoutData,
      getGiftCardsPurchaseStatus,
      getGiftCardsPurchases,
      putGiftCardPurchaseMarkPaid,
      putGiftCardPurchaseMarkIssued,
      getGiftCardBookingDetails,
      getGiftCardClosedDates,
      loadGiftCardUnitsInfo,
      getGiftCardDetailsPdf,
      getGiftCardDetailsQrCode,
      setGiftCardDetails,
      loadPropertyUnitRatePlansList,
      createMultiGiftCardType,
      updateMultiGiftCardType,
      deleteGiftCardTypePhoto,
      deleteMultiGiftCardTypePhoto,
      resetGiftCardStoreBooking,
      getGiftCardDetailsPdfPreview,
      putGiftCard,
      putResetGiftCard,
      getUserHotelRoomTypeRatePlans,
      getGiftCardsExport,
      getGiftCardPdfTemplates,
      getGiftCardPdfTemplateFilledSections,
    ],
  );

  return useMemo(
    () => ({ propertyGiftCardsTypeData, propertyGiftCardTypesActions }),
    [propertyGiftCardsTypeData, propertyGiftCardTypesActions],
  );
};
