import dateFormatter from 'utils/date_formatter';
import isCarProperty from 'utils/is_car_property';

import transport from './utils/transport';

const AGENCY_PATH_PREFIX = '/api/v1/agents';
const RESELLER_PATH_PREFIX_V2 = '/api/v2/resellers';
const RESELLER_PATH_PREFIX_V3 = '/api/v3/resellers';
const SUPPLIER_PATH_PREFIX = '/api/v1/reserva_hotels';
const API_KEYS_PREFIX = '/api/v1/api_keys';

export default {
  getCheckResellerPaymentSource: () => {
    return transport.get(`${RESELLER_PATH_PREFIX_V2}/stripe/check_payment_source`);
  },

  getResellerStripeCustomerPortalUrl: () => {
    return transport.get(`${RESELLER_PATH_PREFIX_V2}/stripe/customers_portal_session`);
  },

  getPropertyInfo: (propertyChannelId, propertyType) => {
    return transport.get(`${RESELLER_PATH_PREFIX_V2}/${propertyType}s/${propertyChannelId}/info`);
  },

  getClosedDates: (propertyChannelId, propertyType) => {
    return transport.get(`${RESELLER_PATH_PREFIX_V2}/${propertyType}s/${propertyChannelId}/closed_dates`);
  },

  getPropertyUnitsInfo: (propertyChannelId, queryParams) => {
    const { checkinDate, checkoutDate, ...restParams } = queryParams;

    let formattedQueryParams = {
      ...restParams,
    };

    const formattedDates = {
      checkinDate: dateFormatter.toApi(checkinDate),
      checkoutDate: dateFormatter.toApi(checkoutDate),
    };

    if (formattedDates.checkinDate && formattedDates.checkoutDate) {
      formattedQueryParams = { ...formattedQueryParams, ...formattedDates };
    }

    formattedQueryParams = {
      ...formattedQueryParams,
      withVirtualRatePlans: true,
    };

    return transport.get(
      `${RESELLER_PATH_PREFIX_V2}/${queryParams.type}s/${propertyChannelId}/list_with_rates`,
      formattedQueryParams,
    );
  },

  getBestOffer: (propertyChannelId, propertyType, queryParams) => {
    const formattedQueryParams = { ...queryParams };

    if (queryParams.checkinDate && queryParams.checkoutDate) {
      formattedQueryParams.checkinDate = dateFormatter.toApi(queryParams.checkinDate);
      formattedQueryParams.checkoutDate = dateFormatter.toApi(queryParams.checkoutDate);
    }

    return transport.get(
      `${RESELLER_PATH_PREFIX_V2}/${propertyType}s/${propertyChannelId}/best_offer`,
      formattedQueryParams,
    );
  },

  createAccommodationBooking: (propertyChannelId, propertyType, booking, queryParams) => {
    return transport.post(
      `${RESELLER_PATH_PREFIX_V2}/${propertyType}s/${propertyChannelId}/create_booking`,
      { booking },
      queryParams,
    );
  },

  createVehicleBooking: (propertyChannelId, propertyType, booking, queryParams) => {
    return transport.post(
      `${RESELLER_PATH_PREFIX_V3}/${propertyType}s/bookings`,
      { booking: { ...booking, vehicleChannelId: propertyChannelId } },
      queryParams,
    );
  },

  cancelAccommodationBooking: params => {
    const { propertyChannelId, propertyType, ...queryParams } = params;
    return transport.put(`${RESELLER_PATH_PREFIX_V2}/${propertyType}s/cancel_booking`, null, queryParams);
  },

  cancelVehicleBooking: params => {
    const { propertyType, reservationId } = params;
    return transport.delete(`${RESELLER_PATH_PREFIX_V3}/${propertyType}s/bookings/${reservationId}`);
  },

  cancelPropertyBooking: params => {
    const { selectedProperty, propertyChannelId, ...queryParams } = params;
    return transport.put(`${SUPPLIER_PATH_PREFIX}/${selectedProperty}/bookings/cancel_booking`, null, queryParams);
  },

  login: loginData => {
    return transport.post('/api/v1/sessions', { session: loginData });
  },

  reload: () => {
    return transport.get('/auth/validate_token');
  },

  resetPassword: email => {
    return transport.post('/auth/forgot_password_email', { email });
  },

  verifyResetPasswordEmail: token => {
    return transport.get('/verify_reset_password_email', { token });
  },

  updatePassword: params => {
    return transport.put('/auth/reset_password', params);
  },

  getCaptureFormUrl: propertyType => {
    return transport.get(`${RESELLER_PATH_PREFIX_V2}/${propertyType}s/card_capture_form_url`);
  },

  getVehiclesList: (propertyType, queryParams, filter = {}) => {
    const requestUrl = `${RESELLER_PATH_PREFIX_V3}/${propertyType}s/list_units`;

    return transport.postWithoutBodyParams(requestUrl, {
      is_available: true,
      ...queryParams,
      ...(filter && { filter }),
    });
  },

  getAdditionalVehiclesList: (propertyType, queryId, filter = {}) => {
    const requestUrl = `${RESELLER_PATH_PREFIX_V3}/${propertyType}s/query_results/${queryId}`;

    return transport.get(requestUrl, { filter });
  },

  getPropertiesList: (propertyType, queryParams, filter = {}) => {
    const requestUrl = `${RESELLER_PATH_PREFIX_V2}/${propertyType}s/list`;

    if (queryParams) {
      const { nw: _nw, se: _se, sw: _sw, ne: _ne, ...paramsWithoutFilter } = queryParams;
      return transport.get(requestUrl, { is_available: true, filter, ...paramsWithoutFilter });
    }

    return transport.get(requestUrl, { filter });
  },

  loadPropertiesPlaces: (propertyType, params) => {
    const requestUrl = `${RESELLER_PATH_PREFIX_V3}/${propertyType}s/places`;

    return transport.get(`${requestUrl}`, params);
  },

  getSuppliersList: () => {
    const filters = {
      pagination: {
        page: 1,
        limit: 1000,
      },
    };
    const requestUrl = `${RESELLER_PATH_PREFIX_V2}/suppliers`;

    return transport.get(requestUrl, filters);
  },

  getResellersList: propertyId => {
    const filters = {
      pagination: {
        page: 1,
        limit: 1000,
      },
    };
    const requestUrl = `${SUPPLIER_PATH_PREFIX}/${propertyId}/agents`;
    return transport.get(requestUrl, filters);
  },

  getResellerUsers: () => {
    const requestUrl = `${RESELLER_PATH_PREFIX_V2}/resellers`;
    return transport.get(requestUrl);
  },

  getResellerBookingsList: params => {
    return transport.get(`${RESELLER_PATH_PREFIX_V2}/bookings`, params);
  },

  getSupplierBookingsList: ({ propertyId, ...params }) => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/bookings`, params);
  },

  getPropertyBookingInfo: ({ propertyId, bookingId }) => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/bookings/${bookingId}`);
  },

  updateBookingGuests: ({ propertyType, bookingId, units }) => {
    const booking_units = isCarProperty(propertyType) ? 'rental_vehicles' : 'booking_rooms';

    return transport.put(`${RESELLER_PATH_PREFIX_V2}/bookings/${bookingId}/${booking_units}/bulk_update`, units);
  },

  deleteBookingUnits: ({ propertyType, bookingId, deleteUnitIds }) => {
    const booking_units = isCarProperty(propertyType) ? 'rental_vehicles' : 'booking_rooms';

    return transport.put(`${RESELLER_PATH_PREFIX_V2}/bookings/${bookingId}/${booking_units}/bulk_remove`, {
      booking: { deleteUnitIds },
    });
  },

  updateBookingSpecialRequest: ({ propertyType, propertyId, bookingId, ...booking }) => {
    return transport.put(
      `${RESELLER_PATH_PREFIX_V2}/${propertyType}s/${propertyId}/bookings/${bookingId}/update_special_request`,
      { booking: { ...booking, propertyType, propertyChannelId: propertyId } },
    );
  },

  // Property details

  getCheckSupplierPaymentSource: propertyId => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/stripe/check_payment_source`);
  },

  getSupplierStripeCustomerPortalUrl: propertyId => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/stripe/customers_portal_session`);
  },

  getSupplierPropertiesList: params => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/properties`, params);
  },

  getPropertySettings: propertyId => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/properties/${propertyId}`);
  },

  updatePropertySettings: ({ propertyId, ...params }) => {
    return transport.put(`${SUPPLIER_PATH_PREFIX}/properties/${propertyId}`, params);
  },

  updatePropertyGiftCardsStoreSettings: ({ propertyId, storeSettings }) => {
    return transport.put(`${SUPPLIER_PATH_PREFIX}/properties/${propertyId}/store_settings`, storeSettings, null, false);
  },

  createPropertyGiftCardsStoreSlug: ({ propertyId, slugParams }) => {
    return transport.post(`${SUPPLIER_PATH_PREFIX}/properties/${propertyId}/slugs`, { slugParams });
  },

  getPropertyFacilities: propertyId => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/property_facilities/options`);
  },

  getPropertyGroupGiftCardsStores: propertyId => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/stores/group_stores`);
  },

  getPropertyGiftCardsStores: propertyId => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/stores`);
  },

  getPropertyGiftCardsStore: ({ propertyId, storeId }) => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/stores/${storeId}`);
  },

  createPropertyGiftCardsStore: ({ propertyId, storeSettings }) => {
    return transport.post(`${SUPPLIER_PATH_PREFIX}/${propertyId}/stores`, storeSettings, null, false);
  },

  updatePropertyGiftCardsStore: ({ propertyId, storeId, storeSettings }) => {
    return transport.put(`${SUPPLIER_PATH_PREFIX}/${propertyId}/stores/${storeId}`, storeSettings, null, false);
  },

  uploadPropertyPhoto: ({ image, propertyId }) => {
    return transport.post(`${SUPPLIER_PATH_PREFIX}/${propertyId}/upload_image`, image, null, false);
  },

  getPropertyUnits: propertyId => {
    const filters = {
      filter: {
        propertyId,
      },
      pagination: {
        page: 1,
        limit: 100,
      },
      order: {
        insertedAt: 'desc',
      },
    };
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/room_types`, filters);
  },

  getPropertyUnitsOptions: propertyId => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/room_types/options`);
  },

  getRatePlansPerPropertyUnit: params => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${params.propertyId}/rate_plans`, params);
  },

  getPropertyRatePlans: propertyId => {
    const filters = { multi_occupancy: true };
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/rate_plans/options`, filters);
  },

  getPropertyUnitFacilities: propertyId => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/room_facilities/options`);
  },

  getPropertyUnit: ({ propertyId, roomTypeId }) => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/room_types/${roomTypeId}`);
  },

  createPropertyUnit: ({ propertyId, roomType }) => {
    return transport.post(`${SUPPLIER_PATH_PREFIX}/${propertyId}/room_types`, { roomType });
  },

  updatePropertyUnit: ({ propertyId, roomType }) => {
    return transport.put(`${SUPPLIER_PATH_PREFIX}/${propertyId}/room_types/${roomType.id}`, { roomType });
  },

  deletePropertyUnit: ({ propertyId, unitId }) => {
    return transport.delete(`${SUPPLIER_PATH_PREFIX}/${propertyId}/room_types/${unitId}`);
  },

  getPropertyRatePlan: ({ propertyId, ratePlanId }) => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/rate_plans/${ratePlanId}`);
  },

  createPropertyRatePlan: ({ propertyId, ratePlan }) => {
    return transport.post(`${SUPPLIER_PATH_PREFIX}/${propertyId}/rate_plans`, { ratePlan });
  },

  updatePropertyRatePlan: ({ propertyId, ratePlan }) => {
    return transport.put(`${SUPPLIER_PATH_PREFIX}/${propertyId}/rate_plans/${ratePlan.id}`, { ratePlan });
  },

  deletePropertyRatePlan: ({ propertyId, ratePlanId }) => {
    return transport.delete(`${SUPPLIER_PATH_PREFIX}/${propertyId}/rate_plans/${ratePlanId}`);
  },

  // Contracts

  getContractsDiscoverSuppliers: params => {
    return transport.get(`${RESELLER_PATH_PREFIX_V2}/suppliers`, params);
  },

  getContractsDiscoverResellers: ({ propertyId, ...params }) => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/agents`, params);
  },

  getContractPropertyRates: ({ propertyId, propertyType }) => {
    return transport.get(`${RESELLER_PATH_PREFIX_V2}/${propertyType}s/${propertyId}/rates`);
  },

  createResellerInitiatedContract: params => {
    return transport.post(`${RESELLER_PATH_PREFIX_V2}/contracts`, params);
  },

  createContractRequest: params => {
    return transport.post(`${RESELLER_PATH_PREFIX_V2}/contracts/request_contract`, params);
  },
  createSupplierInitiatedContract: ({ propertyId, ...params }) => {
    return transport.post(`${SUPPLIER_PATH_PREFIX}/${propertyId}/contracts`, params);
  },

  getResellerContracts: params => {
    return transport.get(`${RESELLER_PATH_PREFIX_V2}/contracts`, params);
  },

  approveResellerContract: ({ contractId }) => {
    return transport.put(`${RESELLER_PATH_PREFIX_V2}/contracts/${contractId}/approve`);
  },

  terminateResellerContract: ({ contractId }) => {
    return transport.put(`${RESELLER_PATH_PREFIX_V2}/contracts/${contractId}/terminate`);
  },

  declineResellerContract: ({ contractId }) => {
    return transport.put(`${RESELLER_PATH_PREFIX_V2}/contracts/${contractId}/decline`);
  },

  cancelResellerContractProposal: ({ contractId }) => {
    return transport.put(`${RESELLER_PATH_PREFIX_V2}/contracts/${contractId}/cancel`);
  },

  getSupplierContracts: ({ propertyId, ...params }) => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/contracts`, params);
  },

  approveSupplierContract: ({ propertyId, contractId }) => {
    return transport.put(`${SUPPLIER_PATH_PREFIX}/${propertyId}/contracts/${contractId}/approve`);
  },

  terminateSupplierContract: ({ propertyId, contractId }) => {
    return transport.put(`${SUPPLIER_PATH_PREFIX}/${propertyId}/contracts/${contractId}/terminate`);
  },

  declineSupplierContract: ({ propertyId, contractId }) => {
    return transport.put(`${SUPPLIER_PATH_PREFIX}/${propertyId}/contracts/${contractId}/decline`);
  },

  cancelSupplierContractProposal: ({ propertyId, contractId }) => {
    return transport.put(`${SUPPLIER_PATH_PREFIX}/${propertyId}/contracts/${contractId}/cancel`);
  },

  getOneTimeHotelAuthToken: propertyId => {
    return transport.post(`${SUPPLIER_PATH_PREFIX}/${propertyId}/auth/one_time_token`);
  },

  // gift cards

  createGiftCardType: ({ propertyId, giftCardTypeParams }) => {
    return transport.post(`${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_card_types`, giftCardTypeParams, null, false);
  },

  getGiftCardTypes: ({ propertyId }) => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_card_types`);
  },

  createMultiGiftCardType: ({ propertyId, multiGiftCardTypeParams }) => {
    return transport.post(
      `${SUPPLIER_PATH_PREFIX}/${propertyId}/card_types/multi_property_gifts`,
      multiGiftCardTypeParams,
      null,
      false,
    );
  },

  updateMultiGiftCardType: ({ propertyId, multiGiftCardTypeParams, multiPropertyGiftId }) => {
    return transport.put(
      `${SUPPLIER_PATH_PREFIX}/${propertyId}/card_types/multi_property_gifts/${multiPropertyGiftId}`,
      multiGiftCardTypeParams,
      null,
      false,
    );
  },

  deleteMultiGiftCardTypePhoto: ({ propertyId, multiPropertyGiftId, giftCardTypeId, photoId }) => {
    return transport.delete(
      `${SUPPLIER_PATH_PREFIX}/${propertyId}/card_types/multi_property_gifts/${multiPropertyGiftId}/types/${giftCardTypeId}/photos/${photoId}`,
      null,
      null,
      false,
    );
  },

  getStoreGiftCardTypes: ({ propertyId }) => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_cards_store`);
  },

  createGiftCard: ({ propertyId, giftCardParams }) => {
    return transport.post(`${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_cards_store/purchase`, { giftCardParams });
  },

  getGiftCardById: ({ propertyId, giftCardId }) => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_cards_store/card/${giftCardId}`);
  },

  getGiftCardPropertyInfo: ({ propertyId }) => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_cards_store/hotel/info`);
  },

  // BEP
  getChannelsFromProperty: propertyId => {
    return transport.get(`${AGENCY_PATH_PREFIX}/hotels/${propertyId}/channels`);
  },

  updateGiftCardType: ({ propertyId, giftCardTypeParams, giftCardTypeId }) => {
    return transport.put(
      `${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_card_types/${giftCardTypeId}`,
      giftCardTypeParams,
      null,
      false,
    );
  },

  deleteGiftCardTypePhoto: ({ propertyId, giftCardTypeId, photoId }) => {
    return transport.delete(
      `${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_card_types/${giftCardTypeId}/photos/${photoId}`,
      null,
      null,
      false,
    );
  },

  setGiftCardCheckoutData: ({ propertyId, giftCardParams }) => {
    return transport.post(`${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_cards_store/checkout`, { giftCardParams });
  },

  getAvailableUnits: ({ giftCardBookingParams }) => {
    return transport.post(`${SUPPLIER_PATH_PREFIX}/gift_cards_booking_engine/available`, { giftCardBookingParams });
  },

  getGiftCardUnit: ({ giftCardBookingParams }) => {
    return transport.post(`${SUPPLIER_PATH_PREFIX}/gift_cards_booking_engine/room_type`, { giftCardBookingParams });
  },

  getGiftCardsByPropertyId: ({ propertyId, ...params }) => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_cards`, params);
  },

  putGiftCard: ({ propertyId, giftCardId, ...giftCardParams }) => {
    return transport.put(`${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_cards/${giftCardId}`, {
      giftCardParams,
    });
  },

  putResetGiftCard: ({ propertyId, giftCardId, ...resetGiftCardParams }) => {
    return transport.put(`${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_cards/${giftCardId}/reset`, {
      resetGiftCardParams,
    });
  },

  putGiftCardEmailSent: ({ propertyId, giftCardId }) => {
    return transport.put(`${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_cards_store/${giftCardId}/mark_email`);
  },

  postGiftCardIssuedMultiple: ({ propertyId, giftCardId, issuedParams }) => {
    return transport.post(`${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_card_types/${giftCardId}/issue_cards3`, {
      issuedParams,
    });
  },

  downloadGiftCardsPdfs: ({ propertyId, purchaseId }) => {
    return transport.get(
      `${SUPPLIER_PATH_PREFIX}/${propertyId}/purchases/${purchaseId}/gift_cards_pdfs`,
      null,
      false,
      false,
    );
  },

  uploadGiftCardTypePhoto: ({ image, propertyId }) => {
    return transport.post(`${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_card_types/upload_image`, image, null, false);
  },

  createGiftCardBooking: booking => {
    return transport.post(`${SUPPLIER_PATH_PREFIX}/gift_cards_booking_engine/push_booking`, { booking });
  },

  cancelGiftCardBooking: cancelBookingParams => {
    return transport.put(`${SUPPLIER_PATH_PREFIX}/gift_cards_booking_engine/cancel_booking`, { cancelBookingParams });
  },

  getGiftCardBookingDetails: ({ storeSlug, giftCardNumber, entry }) => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${storeSlug}/gift_cards_booking_engine/booking_details/${giftCardNumber}?entry=${entry}`);
  },

  getGiftCardDetails: ({ storeSlug, giftCardId, propertyId }) => {
    return transport.get(
      `${SUPPLIER_PATH_PREFIX}/${storeSlug}/gift_cards_booking_engine/gift_card_types/${giftCardId}`,
      propertyId && {
        propertyId,
      },
    );
  },

  getGiftCardTypeSizes: ({ propertyId }) => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_card_types/sizes`);
  },

  getGiftCardTypeSize: ({ propertyId, giftCardTypeId }) => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_cards_store/type/size/${giftCardTypeId}`);
  },

  putGiftCardBookingEmailSent: ({ booking_emailSentParams }) => {
    return transport.put(`${SUPPLIER_PATH_PREFIX}/gift_cards_booking_engine/mark_booking_email`, {
      booking_emailSentParams,
    });
  },

  getGiftCardsPurchaseStatus: ({ propertyId, purchaseParams }) => {
    return transport.post(`${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_cards_store/purchase_status`, { purchaseParams });
  },

  getGiftCardsPurchases: ({ propertyId, ...purchaseParams }) => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/purchases`, purchaseParams);
  },

  putGiftCardPurchaseMarkPaid: ({ propertyId, purchaseId }) => {
    return transport.put(`${SUPPLIER_PATH_PREFIX}/${propertyId}/purchases/${purchaseId}/mark_paid`);
  },

  putGiftCardPurchaseMarkIssued: ({ propertyId, purchaseId }) => {
    return transport.put(`${SUPPLIER_PATH_PREFIX}/${propertyId}/purchases/${purchaseId}/mark_issued`);
  },

  putGiftCardPurchaseUpdateRecipients: ({ propertyId, purchaseId, purchaseParams }) => {
    return transport.put(`${SUPPLIER_PATH_PREFIX}/${propertyId}/purchases/${purchaseId}/update_recipients`, {
      purchaseParams,
    });
  },

  getGiftCardClosedDates: giftCardChannelId => {
    return transport.get(
      `${SUPPLIER_PATH_PREFIX}/gift_cards_booking_engine/${giftCardChannelId}/closed_dates_per_rate`,
    );
  },

  getGiftCardRoomsInfo: ({ propertyChannelId, checkinDate, checkoutDate }) => {
    return transport.get(
      `${SUPPLIER_PATH_PREFIX}/gift_cards_booking_engine/${propertyChannelId}/rooms?checkin_date=${checkinDate}&checkout_date=${checkoutDate}`,
    );
  },

  getGiftCardDetailsPdf: ({ giftCardId, propertyId }) => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_cards/${giftCardId}/pdf`, null, false, false);
  },

  getGiftCardDetailsPdfPreview: ({ giftCardTypeId, propertyId }) => {
    return transport.get(
      `${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_card_types/${giftCardTypeId}/pdf_preview`,
      null,
      false,
      false,
    );
  },

  getGiftCardDetailsQrCode: ({ giftCardId, propertyId }) => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_cards/${giftCardId}/qr_code`, null, false, false);
  },

  getGiftCardProperties: ({ giftCardId }) => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/card_types/multi_property/${giftCardId}/gift_card_types`);
  },

  getUserHotelRoomTypeRatePlans: propertyId => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/properties/${propertyId}/room_type_rate_plans`);
  },

  getGiftCardsExport: ({ propertyId, ...params }) => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_cards/export`, params, true, false);
  },

  getGiftCardPdfTemplates: ({ propertyId }) => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/pdf_templates`);
  },

  getGiftCardPdfTemplateFilledSections: ({ propertyId, giftCardTypeId, pdfTemplateId }) => {
    return transport.get(
      `${SUPPLIER_PATH_PREFIX}/${propertyId}/gift_card_types/${giftCardTypeId}/filled_sections/${pdfTemplateId}`,
    );
  },

  // Admin

  getUsers: params => {
    return transport.get('/api/v1/admin_users', params);
  },

  createUser: user => {
    return transport.post('/api/v1/admin_users', { user });
  },

  updateUser: ({ id, ...user }) => {
    return transport.put(`/api/v1/admin_users/${id}`, { user });
  },

  getResellers: params => {
    return transport.get('/api/v1/admin_agents', params);
  },

  putResellerLockOverride: ({ agentId, lockOverride }) => {
    return transport.put(`/api/v1/admin_agents/${agentId}/lock_override`, {
      lockParams: { lockOverride },
    });
  },

  createReseller: agent => {
    return transport.post('/api/v1/admin_agents', { agent });
  },

  updateReseller: ({ id, ...agent }) => {
    return transport.put(`/api/v1/admin_agents/${id}`, { agent });
  },

  getSuppliers: params => {
    return transport.get('/api/v1/admin_reserva_hotels', params);
  },

  createProperty: reservaHotel => {
    return transport.post('/api/v1/admin_reserva_hotels', { reservaHotel });
  },

  updateProperty: ({ id, ...reservaHotel }) => {
    return transport.put(`/api/v1/admin_reserva_hotels/${id}`, { reservaHotel });
  },

  putSupplierLockOverride: ({ supplierId, lockOverride }) => {
    return transport.put(`/api/v1/admin_reserva_hotels/${supplierId}/lock_override`, {
      lockParams: { lockOverride },
    });
  },

  // Supplier users

  getSupplierUsers: params => {
    const { propertyId, ...rest } = params;
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/manager_users`, rest);
  },

  createSupplierUser: params => {
    const { propertyId, user } = params;
    return transport.post(`${SUPPLIER_PATH_PREFIX}/${propertyId}/manager_users`, { user });
  },

  updateSupplierUser: ({ user, propertyId }) => {
    return transport.put(`${SUPPLIER_PATH_PREFIX}/${propertyId}/manager_users/${user.id}`, { user });
  },

  // Gift campaign

  getGiftCampaignOptions: giftCampaignId => {
    return transport.get(`${AGENCY_PATH_PREFIX}/gift_campaigns/${giftCampaignId}`);
  },

  saveGiftCampaignRecipient: ({ giftCampaignId, giftRecipient }) => {
    return transport.post(`${AGENCY_PATH_PREFIX}/gift_campaigns/${giftCampaignId}/gift_recipients`, { giftRecipient });
  },

  getGiftCampaigns: params => {
    return transport.get(`${AGENCY_PATH_PREFIX}/gift_campaigns`, params);
  },

  createGiftCampaign: giftCampaign => {
    return transport.post(`${AGENCY_PATH_PREFIX}/gift_campaigns`, { giftCampaign });
  },

  updateGiftCampaign: ({ giftCampaignId, giftCampaign }) => {
    return transport.put(`${AGENCY_PATH_PREFIX}/gift_campaigns/${giftCampaignId}`, { giftCampaign });
  },

  createGiftCampaignOption: ({ giftCampaignId, giftOption }) => {
    return transport.post(
      `${AGENCY_PATH_PREFIX}/gift_campaigns/${giftCampaignId}/gift_options`,
      giftOption,
      null,
      false,
    );
  },

  updateGiftCampaignOption: ({ giftOptionId, giftCampaignId, giftOption }) => {
    return transport.put(
      `${AGENCY_PATH_PREFIX}/gift_campaigns/${giftCampaignId}/gift_options/${giftOptionId}`,
      giftOption,
      null,
      false,
    );
  },

  // Api Keys

  getApiKeys: params => {
    return transport.get(`${API_KEYS_PREFIX}`, params);
  },

  createApiKey: params => {
    return transport.post(`${API_KEYS_PREFIX}`, params);
  },

  disableApiKey: apiKey => {
    return transport.delete(`${API_KEYS_PREFIX}/${apiKey}`);
  },

  // Payment providers

  getPaymentProviders: propertyId => {
    return transport.get(`${SUPPLIER_PATH_PREFIX}/${propertyId}/providers`);
  },

  createPaymentProvider: ({ propertyId, ...params }) => {
    return transport.post(`${SUPPLIER_PATH_PREFIX}/${propertyId}/providers`, params);
  },

  updatePaymentProvider: ({ propertyId, provider }) => {
    const { id, ...params } = provider;
    return transport.put(`${SUPPLIER_PATH_PREFIX}/${propertyId}/providers/${id}`, params);
  },

  deletePaymentProvider: ({ propertyId, id }) => {
    return transport.delete(`${SUPPLIER_PATH_PREFIX}/${propertyId}/providers/${id}`);
  },
};
